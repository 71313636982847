import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import placeholder from 'lib/placeholder';

import SidebarOffersList from './OffersList';

import LINKS from 'constants/links';

const FeaturedOffersList = forwardRef(
  ({ featuredOffers = [], ...rest }, ref) => (
    <SidebarOffersList
      ref={ref}
      linkProps={{
        children: placeholder('actions.seeAllOffers'),
        href: LINKS.HOME,
        'data-test-selector': 'view-all-featured-offers',
      }}
      offers={featuredOffers}
      title={placeholder('titles.topOffers')}
      {...rest}
    />
  )
);

FeaturedOffersList.displayName = 'FeaturedOffersList';

FeaturedOffersList.propTypes = {
  featuredOffers: PropTypes.arrayOf(
    PropTypes.shape({
      offerId: PropTypes.number.isRequired,
      offerTitle: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerOldPrice: PropTypes.number,
      offerPriceType: PropTypes.string.isRequired,
      offerPhoto: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
    })
  ),
};

export default FeaturedOffersList;
