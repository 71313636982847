import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { memo } from 'react';

import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import { formatDateByDayFullMonthYear } from 'lib/date';
import { getArticleImageLink, toArticleImageAltText } from 'lib/image';
import { toArticleLink } from 'lib/links';

const ArticlesListItem = ({
  articleDescription,
  articlePublished,
  articleSlug,
  articleThumbnail,
  articleTitle,
  imagePriority = false,
  userName,
}) => (
  <NextLink href={toArticleLink(articleSlug)} passHref>
    <Anchor className="mb-6 md:mb-7">
      <div
        className="flex flex-col md:flex-row"
        data-test-selector="blog-post-item"
      >
        <Image
          alt={toArticleImageAltText(articleTitle)}
          className="w-full rounded-2 object-cover md:max-h-[168px] md:max-w-[260px]"
          height={260}
          loading={imagePriority ? null : 'lazy'}
          priority={imagePriority}
          src={getArticleImageLink(articleThumbnail, 400)}
          width={343}
        />
        <div className="mt-4 flex flex-col justify-between md:ml-4 md:mt-0 md:py-4">
          <Heading as="h2" className="mb-2 md:mb-0" size="size5">
            {articleTitle}
          </Heading>
          <Text className="mb-3 line-clamp-3 md:mb-0 md:line-clamp-2">
            {articleDescription}
          </Text>
          <div className="flex">
            <Text size="size2">{userName}</Text>
            <Text className="mx-2" size="size2">
              •
            </Text>
            <Text size="size2">
              {formatDateByDayFullMonthYear(articlePublished)}
            </Text>
          </div>
        </div>
      </div>
    </Anchor>
  </NextLink>
);

ArticlesListItem.propTypes = {
  articleDescription: PropTypes.string.isRequired,
  articlePublished: PropTypes.string.isRequired,
  articleSlug: PropTypes.string.isRequired,
  articleThumbnail: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
  imagePriority: PropTypes.bool,
  userName: PropTypes.string.isRequired,
};

export default memo(ArticlesListItem);
