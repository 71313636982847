import { appendQsParams, get } from 'lib/request';

export const getArticleCategoriesList = () => get('/article/tags/categories');

export const getArticleInfo = async ({ articleSlug }) =>
  get(`/article/${articleSlug}`);

export const getArticlesList = async ({ limit, after }, opts) =>
  get(
    appendQsParams(`/articles`, {
      after,
      limit,
    }),
    opts
  );

export const getArticleTagInfo = async ({ tagSlug }) =>
  get(`/article/tag/${tagSlug}`);

export const getArticlesListByCategory = async (
  { categorySlug, limit, after },
  opts
) =>
  get(
    appendQsParams(`/article/category/${categorySlug}/articles`, {
      limit,
      after,
    }),
    opts
  );

export const getArticleCategoryInfo = async ({ categorySlug }) =>
  get(`/article/category/${categorySlug}`);

export const getLastArticleReviews = () => get('/article/reviews');

getLastArticleReviews.fallback = { articles: [] };

export const getTagArticlesList = async ({ tagSlug, limit, after }, opts) =>
  get(
    appendQsParams(`/article/tag/${tagSlug}/articles`, { limit, after }),
    opts
  );

getTagArticlesList.fallback = { articles: [] };
