import PropTypes from 'prop-types';
import { useRef } from 'react';

import Sidebar from 'components/Sidebar';
import FeaturedOffersList from 'components/Sidebar/FeaturedOffersList';
import OffersSidebarBox from 'components/Sidebar/OffersSidebarBox';
import SidebarRelevantSubcategories from 'components/Sidebar/RelevantSubcategories';

const BlogSidebar = ({ featuredOffers = [] }) => {
  const featuredOffersListRef = useRef(null);
  const offersSidebarBoxRef = useRef(null);

  return (
    <Sidebar
      className="col-span-4 grid xl:col-span-3"
      refsToBeIgnoredBeforeFixing={[offersSidebarBoxRef, featuredOffersListRef]}
    >
      <OffersSidebarBox ref={offersSidebarBoxRef} />
      <FeaturedOffersList
        ref={featuredOffersListRef}
        className="mt-4"
        featuredOffers={featuredOffers}
      />
      <SidebarRelevantSubcategories className="mt-4" />
    </Sidebar>
  );
};

BlogSidebar.propTypes = {
  featuredArticles: PropTypes.arrayOf(
    PropTypes.shape({
      articleDescription: PropTypes.string.isRequired,
      articleId: PropTypes.number.isRequired,
      articleImage: PropTypes.string.isRequired,
      articlePublished: PropTypes.string.isRequired,
      articleModifiedDate: PropTypes.string.isRequired,
      articleSlug: PropTypes.string.isRequired,
      articleThumbnail: PropTypes.string.isRequired,
      articleTitle: PropTypes.string.isRequired,
      articleTags: PropTypes.arrayOf(
        PropTypes.shape({
          tagId: PropTypes.string.isRequired,
          tagName: PropTypes.string.isRequired,
          tagSlug: PropTypes.string.isRequired,
        })
      ),
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      commentCount: PropTypes.number.isRequired,
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ),
  featuredOffers: PropTypes.arrayOf(
    PropTypes.shape({
      offerId: PropTypes.number.isRequired,
      offerTitle: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerOldPrice: PropTypes.number,
      offerPriceType: PropTypes.string.isRequired,
      offerPhoto: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
    })
  ),
};

export default BlogSidebar;
