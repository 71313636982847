import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Heading from 'shopper/components/Heading';

import { noop } from 'lib/utils';

import MainArticlesItem from './MainArticlesItem';

const MainArticlesRelatedCategories = dynamic(
  () => import('./MainArticlesRelatedCategories')
);

const MAIN_ARTICLES_LENGTH = 3;

const MainArticles = ({
  articles = [],
  relatedCategories = [],
  title = null,
  onArticleClick = noop,
}) => {
  if (!title && articles?.length === 0 && relatedCategories?.length === 0) {
    return null;
  }

  return (
    <Box className="mb-6 rounded-none md:p-6">
      {title && (
        <Heading
          as="h1"
          className="px-4 pt-6 md:mb-4 md:px-0 md:pt-6"
          size="size2"
        >
          {title}
        </Heading>
      )}
      {relatedCategories.length > 0 && (
        <MainArticlesRelatedCategories relatedCategories={relatedCategories} />
      )}
      {articles?.length === MAIN_ARTICLES_LENGTH && (
        <div
          className={twJoin(
            title && 'mt-6',
            'grid grid-cols-1 sm:grid-rows-[repeat(3,_220px)] md:gap-4 lg:grid-cols-2 lg:grid-rows-[repeat(2,_244px)]'
          )}
        >
          {articles.map((article, index) => (
            <MainArticlesItem
              key={article.articleId}
              index={index}
              onClick={() => onArticleClick(article)}
              {...article}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

MainArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      articleDescription: PropTypes.string.isRequired,
      articleId: PropTypes.number.isRequired,
      articleImage: PropTypes.string.isRequired,
      articlePublished: PropTypes.string.isRequired,
      articleSlug: PropTypes.string.isRequired,
      articleThumbnail: PropTypes.string.isRequired,
      articleTitle: PropTypes.string.isRequired,
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      commentCount: PropTypes.number.isRequired,
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      articleTags: PropTypes.arrayOf(
        PropTypes.shape({
          tagId: PropTypes.string.isRequired,
          tagName: PropTypes.string.isRequired,
          tagSlug: PropTypes.string.isRequired,
        })
      ),
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ),
  relatedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  onArticleClick: PropTypes.func,
};

export default MainArticles;
